.heading {
  display: flex;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.2);

  text-align: center;
  color: white;

  container-name: header;
  position: relative;
  z-index: 1;
}

.heading-padding {
  padding-right: 50px;
}

.heading-padding-mobile {
  padding-right: 10px;
}

.item {
  vertical-align: middle;
  list-style-type: none;
  padding-left: 10px;
  width: 100px;
  height: 100%;
  display: inline;
}

.center-align {
}

.item-right {
  margin-left: auto;
}

.heading-title-wrapper {
  width: 300px;
  line-height: 35px;
}

.heading-title {
  margin: 0;
  width: 400px;
  text-align: left;
  font-weight: 900;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 10px;
  font-size: 1.75em;
}

a { color:inherit; }
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.heading-logo {
  pointer-events: none;

  height: 100px;
  min-width: 100px;
  object-fit: cover;
  overflow: hidden;
}

@media screen and (max-width: 512px) {
  .hide_logo {
    display: none;
  }
}

.menu-button {
  width: 32px;
  height: 32px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  left: -35px;
  display: none;
  position: absolute;
}

.dropdown-content-mobile {
  display: none;
  position: absolute;
  left: -100px;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropdown-content-mobile {
  display: block;
}

.dropdown-content-inner {
  position: relative;
  top: 40px;
  min-width: 160px;
  right: 15px;

  background-color: rgba(25, 25, 25, 0.9);
  box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
}

.dropdown-content-inner-mobile {
  position: relative;
  top: 50px;
  right: 0;
}

.dropdown-item {
  padding: 8px;
}

.dropdown-item:hover {
  background-color: white;
  color: black;
  text-decoration-color: black;
}