.rounded-button-style {
    position: relative;
    font-weight: 500;
    font-size: 18px;
}

.rounded-button-wrapper {
    justify-content: center;
    align-content: center;
    flex-direction: column;
    display:flex;
    width: 200px;
    height: 60px;
    border-width: 5px;
    background-color: #333333;
    border-radius: 48px;

    text-align: center;
}