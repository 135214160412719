.about {
    padding-top: 3vh;
    padding-bottom: 50px;
}
.about-item {
    width: 100%;
    list-style-type: none;
    text-align: left;
}
.about-button-style {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0;

    text-align: left;
    font-size: 20px;
    font-weight: 700;

    background: none;
    color: inherit;

    border: 0 solid #cccccc;
    border-bottom-width: 1px;

    cursor: pointer;
    outline: inherit;
}

.about-info {
    margin-top: 5px;
    margin-bottom: 5px;
}

.about-subheader {
    color: #777777;
}

.ul-no-padding {
    padding-left: 0;
}