.image-backdrop {
    position: absolute;
    top: -20vh;
    height: 110vh;

    max-height: 100%;
    min-width: 100%;

    background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.25) 0%,
                    rgba(0, 0, 0, 0.25) 100%
            ),
            url('../../images/shutterstock_746685397.jpg');

    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto, cover;
    z-index: -1;
}

.content {
    margin: auto;
    width: 60%;
    height: 60vh;
    text-align: right;
}

.image-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    align-items: flex-end;

    font-size: 22px;

    margin-top: 20vh;
    margin-bottom: 15vh;

}

.image-content-center {
    text-decoration: underline;

    font-weight: 700;
}

.image-content-center-button-margin {
    margin-top: 20px;
}

.image-content-bottom {
    margin-top: auto;
    font-size: 20px;
}