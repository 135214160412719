body {
    background-color: black;
    color: white;
    text-decoration-color: white;
    text-align: center;
}

h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 40px;
}
.title-style {
    font-family: 'Source Sans Pro', sans-serif;
}
.title-text {
    font-weight: 900;
    font-size: 40px;
}
h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 35px;
}
h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 30px;
}
h4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 25px;
}

@media (max-width:960px) {
    .text-content-center {
        width: 90%;
        margin: auto;
        text-align: center;
    }
}

@media (min-width:960px) {
    .text-content-center {
        width: 60%;
        margin: auto;
        text-align: center;
    }
}

@media (max-width:960px) {
    .text-content-left {
        width: 90%;
        margin: auto;
        text-align: left;
    }
}

@media (min-width:960px) {
    .text-content-left {
        width: 60%;
        margin: auto;
        text-align: left;
    }
}

.center {
    text-align: center;
}

.sub-text {
    color: #aaaaaa;
}

.inverted-color {
    background-color: white;
    color: black;
    text-decoration-color: black;
}

.no-style-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.highlighted-link {
    color: white;
    text-decoration: underline;
    text-underline: white;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
}