.footer {
    margin: 100px auto;
    width: 70%;

    color: #aaaaaa;

    text-align: center;
}

.footer-grouped {
    margin: 0;
    line-height: 140%;
}