.apply-now {
    margin: 3vh auto auto;
}

@media (max-width:960px) {
    .form-large-item {
        width: 100%;
    }
}

@media (min-width:960px) {
    .form-large-item {
        width: 50%;
        float: left;
    }
}

.form-item {
    width: 95%;
    padding: 10px 0 10px 10px;
    margin: 10px 0 10px 0;
}

textarea {
    padding: 10px;
    margin: 10px;
    resize: none;
    width: 95%;
    height: 220px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}